<template>
  <section
      id="summary-stat-compare"
      data-app
  >
    <b-row
        v-if="hasProjects"
        class="match-height"
    >
      <transition
          mode="out-in"
          name="fade"
      >
        <b-col
            v-show="emptyStats !== 'NO_STATS'"
            cols="12"
        >
          <div class="d-flex align-items-center">
            <feather-icon
                icon="CalendarIcon"
                size="16"
            />
            <flat-pickr
                v-model="rangeDataOne"
                :config="dataPickerConfig"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
            />
          </div>

          <div class="d-flex align-items-center">
            <feather-icon
                icon="CalendarIcon"
                size="16"
            />
            <flat-pickr
                v-model="rangeDataTwo"
                :config="dataPickerConfig"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
            />
          </div>
          <div>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                @click="changeDatesRange"
            >
              Сравнить периоды
            </b-button>
          </div>
          <div>
            <b-table
                ref="table"
                :data="data"
                :loading="isLoading"
                :per-page="perPage.value"
                :show-detail-icon="showDetailIcon"
                :sticky-header="stickyHeaders"
                class="mt-2"
                custom-detail-row
                detail-key="id"
                detailed
                height="100vh"
                hoverable
            >
              <!-- project -->
              <b-table-column
                  v-slot="props"
                  :label="columnsVisible['project'].title"
                  field="project"
                  sortable
                  width="300"
              >
                <template v-if="showDetailIcon">
                  {{ props.row.project }}
                </template>
                <template v-else>
                  <a @click="toggle(props.row)">
                    {{ props.row.project }}
                  </a>
                </template>
              </b-table-column>

              <!-- system -->
              <b-table-column
                  v-slot="props"
                  :label="columnsVisible['system'].title"
                  field="system"
                  sortable
                  width="300"
              >
                {{ props.row.system }}
              </b-table-column>

              <!-- consumption period 1-->
              <b-table-column
                  v-slot="props"
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumptionPeriodOne')"
                  :label="columnsVisible['consumptionPeriodOne'].title"
                  :visible="columnsVisible['consumptionPeriodOne'].display"
                  centered
                  field="consumptionPeriodOne"
                  sortable
              >
                {{ columnValueFormat(props.row.consumptionPeriodOne) }}
              </b-table-column>

              <!-- consumption period 2-->
              <b-table-column
                  v-slot="props"
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumptionPeriodTwo')"
                  :label="columnsVisible['consumptionPeriodTwo'].title"
                  :visible="columnsVisible['consumptionPeriodTwo'].display"
                  centered
                  field="consumptionPeriodTwo"
                  sortable
              >
                {{ columnValueFormat(props.row.consumptionPeriodTwo) }}
              </b-table-column>

              <!-- consumption delta-->
              <b-table-column
                  v-slot="props"
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumptionDelta')"
                  :label="columnsVisible['consumptionDelta'].title"
                  :visible="columnsVisible['consumptionDelta'].display"
                  centered
                  field="consumptionDelta"
                  sortable
              >
                {{ columnValueFormat(props.row.consumptionDelta) }}%
              </b-table-column>

              <!-- kpi1 plan period 1-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1PlanPeriodOne')"
                  :label="columnsVisible['kpi1PlanPeriodOne'].title"
                  :visible="columnsVisible['kpi1PlanPeriodOne'].display"
                  centered
                  field="kpi1PlanPeriodOne"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1PlanPeriodOne'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi1PlanPeriodOne) }}
                </template>
              </b-table-column>

              <!-- kpi1 fact period 1-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1FactPeriodOne')"
                  :label="columnsVisible['kpi1FactPeriodOne'].title"
                  :visible="columnsVisible['kpi1FactPeriodOne'].display"
                  centered
                  field="kpi1FactPeriodOne"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1FactPeriodOne'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi1FactPeriodOne) }}
                </template>
              </b-table-column>

              <!-- kpi1 fact period 2-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1FactPeriodTwo')"
                  :label="columnsVisible['kpi1FactPeriodTwo'].title"
                  :visible="columnsVisible['kpi1FactPeriodTwo'].display"
                  centered
                  field="kpi1FactPeriodTwo"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1FactPeriodTwo'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{
                    props.row.kpi1FactPeriodTwo === '-' ? props.row.kpi1FactPeriodTwo : `${columnValueFormat(props.row.kpi1FactPeriodTwo)}`
                  }}
                </template>
              </b-table-column>

              <!-- kpi1 delta-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi1Delta')"
                  :label="columnsVisible['kpi1Delta'].title"
                  :visible="columnsVisible['kpi1Delta'].display"
                  centered
                  field="kpi1Delta"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi1Delta'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi1Delta) }}%
                </template>
              </b-table-column>

              <!-- kpi2 plan period 1-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2PlanPeriodOne')"
                  :label="columnsVisible['kpi2PlanPeriodOne'].title"
                  :visible="columnsVisible['kpi2PlanPeriodOne'].display"
                  centered
                  field="kpi2PlanPeriodOne"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2PlanPeriodOne'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2PlanPeriodOne) }}
                </template>
              </b-table-column>

              <!-- kpi2 fact period 1-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2FactPeriodOne')"
                  :label="columnsVisible['kpi2FactPeriodOne'].title"
                  :visible="columnsVisible['kpi2FactPeriodOne'].display"
                  centered
                  field="kpi2FactPeriodOne"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2FactPeriodOne'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2FactPeriodOne) }}
                </template>
              </b-table-column>

              <!-- kpi2 fact period 2-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2FactPeriodTwo')"
                  :label="columnsVisible['kpi2FactPeriodTwo'].title"
                  :visible="columnsVisible['kpi2FactPeriodTwo'].display"
                  centered
                  field="kpi2FactPeriodTwo"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2FactPeriodTwo'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2FactPeriodTwo) }}
                </template>
              </b-table-column>

              <!-- kpi2 delta-->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'kpi2Delta')"
                  :label="columnsVisible['kpi2Delta'].title"
                  :visible="columnsVisible['kpi2Delta'].display"
                  centered
                  field="kpi2Delta"
                  sortable
              >
                <template v-slot:header="{ column }">
                  <b-tooltip :title="columnsVisible['kpi2Delta'].tooltip">
                    {{ column.label }}
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpi2Delta) }}%
                </template>
              </b-table-column>

              <template
                  slot="detail"
                  slot-scope="props"
              >

                <tr
                    v-for="item in props.row.items"
                    :key="item.id"
                >
                  <td v-if="showDetailIcon"/>
                  <td/>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ item.system }}
                  </td>

                  <!-- consumption -->
                  <td
                      v-show="columnsVisible['consumptionPeriodOne'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.consumptionPeriodOne) }}
                  </td>
                  <td
                      v-show="columnsVisible['consumptionPeriodTwo'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.consumptionPeriodTwo) }}
                  </td>
                  <td
                      v-show="columnsVisible['consumptionDelta'].display"
                      class="has-text-centered"
                  >
                    {{
                      item.consumptionDelta === '-' ? item.consumptionDelta : `${columnValueFormat(item.consumptionDelta)}%`
                    }}
                  </td>

                  <!-- kpi1 -->
                  <td
                      v-show="columnsVisible['kpi1PlanPeriodOne'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi1PlanPeriodOne) }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi1FactPeriodOne'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi1FactPeriodOne) }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi1FactPeriodTwo'].display"
                      class="has-text-centered"
                  >
                    {{
                      item.kpi1FactPeriodTwo === '-' ? item.kpi1FactPeriodTwo : `${columnValueFormat(item.kpi1FactPeriodTwo)}`
                    }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi1Delta'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi1Delta) }}%
                  </td>

                  <!-- kpi2 -->
                  <td
                      v-show="columnsVisible['kpi2PlanPeriodOne'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi2PlanPeriodOne) }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi2FactPeriodOne'].display"
                      class="has-text-centered"
                  >
                    {{
                      item.kpi2FactPeriodOne === '-' ? item.kpi2FactPeriodOne : `${columnValueFormat(item.kpi2FactPeriodOne)}`
                    }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi2FactPeriodTwo'].display"
                      class="has-text-centered"
                  >
                    {{
                      item.kpi2FactPeriodTwo === '-' ? item.kpi2FactPeriodTwo : `${columnValueFormat(item.kpi2FactPeriodTwo)}`
                    }}
                  </td>
                  <td
                      v-show="columnsVisible['kpi2Delta'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi2Delta) }}%
                  </td>
                </tr>
              </template>

            </b-table>
          </div>
        </b-col>
      </transition>

      <transition
          mode="out-in"
          name="fade"
      >
        <b-col
            v-show="emptyStats === 'NO_STATS'"
            cols="12"
        >
          <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
              <h2 class="mb-1">
                На проекте нет статистики
              </h2>
              <p class="mb-3">
                Подключите интеграции к вашему проекту
              </p>

              <b-img
                  :src="downImg"
                  alt=""
                  fluid
              />
            </div>
          </div>
        </b-col>
      </transition>
    </b-row>
    <b-row
        v-if="!hasProjects"
        class="match-height"
    >

      <b-col cols="12">
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              У вас нет созданных проектов
            </h2>
            <h4 class="mb-3">
              <b-link @click="tourStartAnalytics">
                Хотите перейти к созданию проекта?
              </b-link>
            </h4>
            <b-img
                :src="downImg"
                alt=""
                fluid
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <app-tour
        :steps="steps"
        name="test"
    />
  </section>
</template>
<script>
import _ from 'lodash'
import { kFormatter } from '@core/utils/filter'
import AppTour from '@core/components/app-tour/AppTour.vue'
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormRow,
  BImg,
  BLink,
  BOverlay,
  BRow,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

// eslint-disable-next-line import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import { MonthPickerInput } from 'vue-month-picker'
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData'

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

const dateFromPeriodOne = new Date().addDays(-14)
const dateToPeriodOne = new Date().addDays(-7)

const dateFromPeriodTwo = new Date().addDays(-7)
const dateToPeriodTwo = new Date()
dateToPeriodOne.setHours(0, 0, 0, 0)
dateFromPeriodOne.setHours(0, 0, 0, 0)
dateToPeriodTwo.setHours(0, 0, 0, 0)
dateFromPeriodTwo.setHours(0, 0, 0, 0)
// eslint-disable-next-line no-extend-native
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1
  const dd = this.getDate()

  // eslint-disable-next-line radix
  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('')
}

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series10: '#a9390c',
    series9: '#316b6b',
    series8: '#e26572',
    series7: '#e369b6',
    series6: '#5dd2db',
    series5: '#7253d0',
    series4: '#4f82cf',
    series3: '#8BC554',
    series2: '#FECC35',
    series1: '#FC4526',
  },
}
vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    BCard,
    MonthPickerInput,
    BFormCheckboxGroup,
    AppTour,
    vSelect,
    VueApexCharts,
    BLink,
    BCardHeader,
    BCardBody,
    BButton,
    BCardTitle,
    BButtonGroup,
    BFormRow,
    BFormGroup,
    BImg,
    BCardSubTitle,
    flatPickr,
    BOverlay,
    BRow,
    BBadge,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      steps: [
        {
          target: '#__BVID__48__BV_toggle_',
          header: {
            title: 'Создайте проект',
          },
          content: 'Кликните и перейдите в раздел "Проекты"',
        },
      ],
      stickyHeaders: true,
      data: [],
      emptyStats: null,
      perPage: {
        value: 30,
        text: '30 на странице',
      },
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      campaign: [],
      campaignOptions: [],
      optionsPerPages: [
        {
          value: 10,
          text: '10 на странице',
        },
        {
          value: 30,
          text: '30 на странице',
        },
        {
          value: 50,
          text: '50 на странице',
        },
        {
          value: 100,
          text: '50 на странице',
        },
        {
          value: 250,
          text: '250 на странице',
        },
        {
          value: 500,
          text: '500 на странице',
        },
      ],
      useTransition: false,
      isLoading: false,
      availableKpi1Options: [
        'Маржинальная прибыль (аналитика)',
        'Маржинальная прибыль (CRM)',
        'Стоимость заказов (аналитика)',
        'Стоимость заказов (CRM)',
        'Roi (аналитика)',
        'Roi (CRM)',
      ],
      availableKpi2Options: [
        'Выручка (аналитика)',
        'Выручка (CRM)',
        'Кол-во заказов (аналитика)',
        'Кол-во заказов (CRM)',
        'Показы',
        'Клики',
      ],
      columnsVisible: {
        project: {
          title: 'Проект',
          display: true,
          type: 'project',
        },
        system: {
          title: 'Система',
          display: true,
          // subheading: 'Всего:',
          type: 'system',
        },
        consumptionPeriodOne: {
          title: 'Расход (период 1)',
          display: true,
          type: 'Трафик',
        },
        consumptionPeriodTwo: {
          title: 'Расход (период 2)',
          display: true,
          type: 'Трафик',
        },
        consumptionDelta: {
          title: 'Дельта',
          display: true,
          type: 'Трафик',
        },
        kpi1PlanPeriodOne: {
          title: 'Kpi1 (план) (от периода 1)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi1FactPeriodOne: {
          title: 'Kpi1 (период 1)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi1FactPeriodTwo: {
          title: 'Kpi1 (период 2)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi1Delta: {
          title: 'Kpi1 - Дельта',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2PlanPeriodOne: {
          title: 'Kpi2 (план) (от периода 1)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2FactPeriodOne: {
          title: 'Kpi2 (период 1)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2FactPeriodTwo: {
          title: 'Kpi2 (период 2)',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
        kpi2Delta: {
          title: 'Kpi2 - Дельта',
          display: false,
          type: 'Трафик',
          tooltip: '',
        },
      },
      showDetailIcon: true,
      items: [],
      rangeDataOne: [dateFromPeriodOne, dateToPeriodOne],
      rangeDataTwo: [dateFromPeriodTwo, dateToPeriodTwo],
      changedRangeDataPeriodOne: [],
      changedRangeDataPeriodTwo: [],
      apexChatData,
      saveStatisticData: [],
      isShowColumnsRow: false,
      dataPickerConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: 'd-m-Y',
      },
      plugins: [
        // eslint-disable-next-line new-cap
        new monthSelectPlugin({
          shorthand: true, // defaults to false
          dateFormat: 'm.y', // defaults to "F Y"
          altFormat: 'F Y', // defaults to "F Y"
          theme: 'dark', // defaults to "light"
        }),
      ],
    }
  },
  computed: {
    localStorageActiveProject() {
      return localStorage.getItem('active_project_id')
    },
    groupedColumns() {
      function getPredicate() {
        return v => v.type !== 'campaign'
      }

      const columns = _.groupBy(_.filter(this.columnsVisible,
          getPredicate()), 'type')
      return columns
    },
    isChanged() {
      return this.$store.state.project.isChanged
    },
    hasProjects() {
      return this.$store.state.project.projects.length !== 0
    },
    tableData() {
      return this.$store.state.statistic.summaryTableCompareData
    },
  },
  mounted() {
    // if (this.hasProjects) {
    //   this.loadStats(this.rangeData)
    // }
    this.loadStats(this.rangeDataOne, this.rangeDataTwo)
  },
  methods: {
    // changeColumnColor(row, column) {
    //   switch (column.field) {
    //     case 'consumptionTrend':
    //       return this.getColorClass(
    //           (Math.abs(row.consumptionTrend) / row.consumptionPeriodTwo - 1) * 100,
    //           false
    //       );
    //     case 'kpi1FactPeriodTwo':
    //       return this.getColorClass((row.kpi1Trend / row.kpi1FactPeriodOne - 1) * 100)
    //     case 'kpi2Trend':
    //       return this.getColorClass(
    //           (row.kpi2Trend / row.kpi2PlanPeriodOne - 1) * 100
    //       );
    //     default:
    //       return { class: 'is-danger' };
    //   }
    // },
    //
    // getColorClass(diff, isKpi = true) {
    //   const lightStyle = {
    //     success: {
    //       class: 'is-light',
    //       style: { backgroundColor: '#d9ead3', color: '#257953' }
    //     },
    //     warning: {
    //       class: 'is-light',
    //       style: { backgroundColor: '#fff2cc', color: '#946c00' }
    //     },
    //     danger: {
    //       class: 'is-light',
    //       style: { backgroundColor: '#f9cb9c', color: '#cc0f35' }
    //     }
    //   }
    //
    //   if (isKpi) {
    //     if (diff >= 20) return { class: 'is-success' };
    //     if (diff >= 8) return lightStyle.success;
    //     if (diff >= -5) return lightStyle.warning;
    //     if (diff >= -10) return { class: 'is-warning' };
    //     if (diff >= -17) return lightStyle.danger;
    //     return { class: 'is-danger' };
    //   } else {
    //     if (diff <= 2) return { class: 'is-success' };
    //     if (diff <= 5) return lightStyle.success;
    //     if (diff <= 10) return lightStyle.warning;
    //     if (diff <= 14) return { class: 'is-warning' };
    //     if (diff <= 18) return lightStyle.danger;
    //     return { class: 'is-danger' };
    //   }
    // },
    kFormatter,
    // showDate(date) {
    //   const dateFormatted = [date.from, date.to]
    //
    //   /*   // Функция для уменьшения даты на один день
    //     function subtractOneDay(formDate) {
    //       const newDate = new Date(formDate)
    //       newDate.setDate(newDate.getDate() - 1)
    //       return newDate
    //     }
    //
    //     // Применение функции к date.to
    //     const newDateFormatted = {
    //       from: date.from,
    //       to: subtractOneDay(date.to),
    //     } */
    //
    //   this.loadStats(dateFormatted)
    // },
    testSignalr() {
      // logout api
      this.$http.get('logger/signal')
          // eslint-disable-next-line no-unused-vars
          .then(response => {
          })
    },
    tourStartAnalytics() {
      this.$tours.test.start()
    },
    columnValueFormat(val) {
      //console.log(val, 'val')
      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (Math.abs(val) < 0.05 || val === 0) {
        return 0
      }

      if (Math.round(+val) !== +val) {
        return val.toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
      }

      return (+val).toLocaleString()
    },
    customSort(a, b, isAsc, val) {
      if (!isAsc) {
        a.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        b.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        return b[val] - a[val]
      }
      a.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      b.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      return a[val] - b[val]
    },

    isDatePickerChanged(dateRange, changedRangeData) {
      return (changedRangeData[0] && changedRangeData[1]
          && dateRange[0] && dateRange[1]
          && changedRangeData[0].getTime() === dateRange[0].getTime()
          && changedRangeData[1].getTime() === dateRange[1].getTime()
      )
    },
    loadStats(dateRange1, dateRange2, isChanged) {
      if (this.isDatePickerChanged(dateRange1, this.changedRangeDataPeriodOne)
          || this.isDatePickerChanged(dateRange2, this.changedRangeDataPeriodTwo)) {
        return
      }

      this.isLoading = true
      this.emptyStats = null
      this.data = []
      this.saveStatisticData = []
      this.campaignOptions = []
      this.changedRangeDataPeriodOne = dateRange1
      this.changedRangeDataPeriodTwo = dateRange2

      if (dateRange1 != null && dateRange2 != null) {
        const startDate1 = dateRange1[0]
        const endDate1 = dateRange1[1]

        const startDate2 = dateRange2[0]
        const endDate2 = dateRange2[1]

        if (startDate1 != null && endDate1 != null && startDate2 != null && endDate2 != null) {
          const filterTableData = {
            StartDatePeriodOne: (+startDate1.yyyymmdd()),
            EndDatePeriodOne: (+endDate1.yyyymmdd()),
            StartDatePeriodTwo: (+startDate2.yyyymmdd()),
            EndDatePeriodTwo: (+endDate2.yyyymmdd()),
          }

          const isDefaultRange1 = (+startDate1.yyyymmdd()) === (+dateFromPeriodOne.yyyymmdd())
              && (+endDate1.yyyymmdd()) === (+dateToPeriodOne.yyyymmdd())
          const isDefaultRange2 = (+startDate2.yyyymmdd()) === (+dateFromPeriodTwo.yyyymmdd())
              && (+endDate2.yyyymmdd()) === (+dateToPeriodTwo.yyyymmdd())

          const isNeedBuild = this.tableData != null
              && isDefaultRange1 && isDefaultRange2

          if (!isChanged && isNeedBuild) {
            this.calculateTableData(this.tableData)
          } else {
            this.buildTable(filterTableData, isDefaultRange1, isDefaultRange2)
          }

          this.$store.commit('project/UPDATE_LOADING_PROJECT', false)
        }
      }
    },
    parseDateForFlatPickr(dateRange) {
      // Пример строки: "01-08-2024 — 15-08-2024"
      if (typeof dateRange === 'string') {
        const dates = dateRange.split(' — ').map(dateStr => {
          const [day, month, year] = dateStr.split('-')
          return new Date(`${year}-${month}-${day}`)
        })
        return dates // [startDate, endDate]
      }
      return [null, null]
    },
    changeDatesRange() {
      const selectedDatesPeriodOne = this.parseDateForFlatPickr(this.rangeDataOne)
      const selectedDatesPeriodTwo = this.parseDateForFlatPickr(this.rangeDataTwo)
      this.loadStats(selectedDatesPeriodOne, selectedDatesPeriodTwo)
    },
    calculateTableData(dataForParse) {
      // table parsed
      let increment = 0
      // let selectedKpiIncrement = 0
      const emptySymbol = null
      let emptySymbolConsumption = emptySymbol
      let emptySymbolKpi1 = emptySymbol
      let emptySymbolKpi2 = emptySymbol
      // const availableKpis = ['Показы', 'Клики', 'Средняя стоимость клика',
      //   'Ctr', 'Выручка (аналитика)', 'Маржинальная прибыль (аналитика)',
      //   'Кол-во заказов (аналитика)', 'Стоимость заказов (аналитика)',
      //   'Cr (аналитика)', 'Выручка (CRM)', 'Маржинальная прибыль (CRM)',
      //   'Кол-во заказов (CRM)', 'Стоимость заказов (CRM)',
      //   'Cr (CRM)', 'Roi (CRM)']

      Object.keys(dataForParse)
          .forEach(key => {
            const statItem = dataForParse[key]
            const stat = {
              id: 0,
              project: key,
              system: '',

              consumptionPeriodOne: emptySymbolConsumption,
              consumptionPeriodTwo: emptySymbolConsumption,
              consumptionDelta: emptySymbolConsumption,

              kpi1PlanPeriodOne: emptySymbolKpi1,
              kpi1FactPeriodOne: emptySymbolKpi1,
              kpi1FactPeriodTwo: emptySymbolKpi1,
              kpi1Delta: emptySymbolKpi1,

              kpi2PlanPeriodOne: emptySymbolKpi2,
              kpi2FactPeriodOne: emptySymbolKpi2,
              kpi2FactPeriodTwo: emptySymbolKpi2,
              kpi2Delta: emptySymbolKpi2,

              items: [],
            }

            const subObjBrend = {
              // eslint-disable-next-line no-plusplus
              id: `stat-brend-${increment}`,
              system: 'Бренд',

              consumptionPeriodOne: emptySymbolConsumption,
              consumptionPeriodTwo: emptySymbolConsumption,
              consumptionDelta: emptySymbolConsumption,

              kpi1PlanPeriodOne: emptySymbolKpi1,
              kpi1FactPeriodOne: emptySymbolKpi1,
              kpi1FactPeriodTwo: emptySymbolKpi1,
              kpi1Delta: emptySymbolKpi1,

              kpi2PlanPeriodOne: emptySymbolKpi2,
              kpi2FactPeriodOne: emptySymbolKpi2,
              kpi2FactPeriodTwo: emptySymbolKpi2,
              kpi2Delta: emptySymbolKpi2,
            }

            const subObjNotBrend = {
              // eslint-disable-next-line no-plusplus
              id: `stat-not-brend-${increment}`,
              system: 'Не бренд',

              consumptionPeriodOne: emptySymbolConsumption,
              consumptionPeriodTwo: emptySymbolConsumption,
              consumptionDelta: emptySymbolConsumption,

              kpi1PlanPeriodOne: emptySymbolKpi1,
              kpi1FactPeriodOne: emptySymbolKpi1,
              kpi1FactPeriodTwo: emptySymbolKpi1,
              kpi1Delta: emptySymbolKpi1,

              kpi2PlanPeriodOne: emptySymbolKpi2,
              kpi2FactPeriodOne: emptySymbolKpi2,
              kpi2FactPeriodTwo: emptySymbolKpi2,
              kpi2Delta: emptySymbolKpi2,
            }

            statItem.forEach(item => {
              stat.system = item.system

              if (item.kpi === 'Расходы') {
                if (stat.consumptionPeriodOne === emptySymbol) emptySymbolConsumption = 0

                // this.columnsVisible.consumptionPeriodOne.display = true
                // this.columnsVisible.consumptionPeriodTwo.display = true
                // this.columnsVisible.consumptionDelta.display = true

                stat.consumptionPeriodOne += parseFloat(item.factPeriodOne)
                stat.consumptionPeriodTwo += parseFloat(item.factPeriodTwo)
                stat.consumptionDelta = parseFloat(item.delta) * 100

                subObjBrend.consumptionPeriodOne += parseFloat(item.factPeriodOneBrend)
                subObjBrend.consumptionPeriodTwo += parseFloat(item.factPeriodTwoBrend)
                subObjBrend.consumptionDelta = parseFloat(item.deltaBrend) * 100

                subObjNotBrend.consumptionPeriodOne += parseFloat(item.factPeriodOneBrend)
                subObjNotBrend.consumptionPeriodTwo += parseFloat(item.factPeriodTwoBrend)
                subObjNotBrend.consumptionDelta = parseFloat(item.deltaNotBrend) * 100
              }

              if (this.availableKpi1Options.includes(item.kpi)) {
                if (stat.kpi1PlanPeriodOne === emptySymbol) emptySymbolKpi1 = 0

                this.columnsVisible.kpi1PlanPeriodOne.display = true
                this.columnsVisible.kpi1FactPeriodOne.display = true
                this.columnsVisible.kpi1FactPeriodTwo.display = true
                this.columnsVisible.kpi1Delta.display = true

                this.columnsVisible.kpi1PlanPeriodOne.tooltip = `${item.kpi} (план периода 1)`
                this.columnsVisible.kpi1FactPeriodOne.tooltip = `${item.kpi} (факт периода 1)`
                this.columnsVisible.kpi1FactPeriodTwo.tooltip = `${item.kpi} (факт периода 2)`
                this.columnsVisible.kpi1Delta.tooltip = `${item.kpi} (дельта)`

                stat.kpi1PlanPeriodOne = parseFloat(item.planPeriodOne)
                stat.kpi1FactPeriodOne += parseFloat(item.factPeriodOne)
                stat.kpi1FactPeriodTwo += parseFloat(item.factPeriodTwo)
                stat.kpi1Delta = parseFloat(item.delta) * 100

                subObjBrend.kpi1PlanPeriodOne = parseFloat(item.planPeriodOneBrend)
                subObjBrend.kpi1FactPeriodOne += parseFloat(item.factPeriodOneBrend)
                subObjBrend.kpi1FactPeriodTwo += parseFloat(item.factPeriodTwoBrend)
                subObjBrend.kpi1Delta = parseFloat(item.deltaBrend) * 100

                subObjNotBrend.kpi1PlanPeriodOne = parseFloat(item.planPeriodOneNotBrend)
                subObjNotBrend.kpi1FactPeriodOne += parseFloat(item.factPeriodOneNotBrend)
                subObjNotBrend.kpi1FactPeriodTwo += parseFloat(item.factPeriodTwoNotBrend)
                subObjNotBrend.kpi1Delta = parseFloat(item.deltaNotBrend) * 100
              }

              if (this.availableKpi2Options.includes(item.kpi)) {
                if (stat.kpi2PlanPeriodOne === emptySymbol) emptySymbolKpi2 = 0

                this.columnsVisible.kpi2PlanPeriodOne.display = true
                this.columnsVisible.kpi2FactPeriodOne.display = true
                this.columnsVisible.kpi2FactPeriodTwo.display = true
                this.columnsVisible.kpi2Delta.display = true

                this.columnsVisible.kpi2PlanPeriodOne.tooltip = `${item.kpi} (план периода 1)`
                this.columnsVisible.kpi2FactPeriodOne.tooltip = `${item.kpi} (факт периода 1)`
                this.columnsVisible.kpi2FactPeriodTwo.tooltip = `${item.kpi} (факт периода 2)`
                this.columnsVisible.kpi2Delta.tooltip = `${item.kpi} (дельта)`

                stat.kpi2PlanPeriodOne = parseFloat(item.planPeriodOne)
                stat.kpi2FactPeriodOne += parseFloat(item.factPeriodOne)
                stat.kpi2FactPeriodTwo += parseFloat(item.factPeriodTwo)
                stat.kpi2Delta = parseFloat(item.delta) * 100

                subObjBrend.kpi2PlanPeriodOne = parseFloat(item.planPeriodOneBrend)
                subObjBrend.kpi2FactPeriodOne += parseFloat(item.factPeriodOneBrend)
                subObjBrend.kpi2FactPeriodTwo += parseFloat(item.factPeriodTwoBrend)
                subObjBrend.kpi2Delta = parseFloat(item.deltaBrend) * 100

                subObjNotBrend.kpi2PlanPeriodOne = parseFloat(item.planPeriodOneNotBrend)
                subObjNotBrend.kpi2FactPeriodOne += parseFloat(item.factPeriodOneNotBrend)
                subObjNotBrend.kpi2FactPeriodTwo += parseFloat(item.factPeriodTwoNotBrend)
                subObjNotBrend.kpi2Delta = parseFloat(item.deltaNotBrend) * 100
              }
            })

            // subObjNotBrend.consumptionPeriodTwo = '-'
            // subObjNotBrend.consumptionDelta = '-'
            // subObjBrend.consumptionDelta = '-'
            //
            // subObjNotBrend.kpi1FactPeriodTwo = '-'
            // subObjBrend.kpi1FactPeriodTwo = '-'
            //
            // subObjBrend.consumptionPeriodTwo = '-'
            // subObjBrend.kpi1FactPeriodOne = '-'
            //
            // subObjNotBrend.kpi2FactPeriodOne = '-'
            // subObjBrend.kpi2FactPeriodOne = '-'
            //
            // subObjBrend.kpi2PlanPeriodOne = '-'
            // subObjNotBrend.kpi2PlanPeriodOne = '-'
            //
            // subObjNotBrend.kpi1FactPeriodOne = '-'
            // subObjBrend.kpi2PlanPeriodOne = '-'

            stat.items.push(subObjBrend)
            stat.items.push(subObjNotBrend)

            // eslint-disable-next-line no-plusplus
            stat.id = `stat${increment++}`
            this.data.push(stat)
            this.saveStatisticData.push(stat)

            //console.log(stat, 'stat')
          })

      /* let indexCampaign = 0
      this.data.map(c => c.campaign)
        .forEach(campaignText => {
          indexCampaign += 1
          this.campaignOptions.push({
            value: indexCampaign,
            text: campaignText,
          })
        }) */
      this.isLoading = false
    },
    // calculateDependedFields(stat) {
    //   // eslint-disable-next-line radix
    //
    //   stat.ctr_fact = stat.showsFact !== 0
    //       ? ((parseInt(stat.clicksFact) / parseInt(stat.showsFact)) * 100) : '-'
    //
    //   stat.avgCpc_fact = stat.clicksFact !== 0
    //       ? ((parseFloat(stat.consumptionPeriodOne) / parseInt(stat.clicksFact))) : '-'
    //
    //   // calculate Analytic values
    //   stat.analyticRoi_fact = stat.consumptionPeriodOne !== 0
    //       ? ((parseFloat(stat.analyticRevenueFact) - parseFloat(stat.consumptionPeriodOne))
    //       / parseFloat(stat.consumptionPeriodOne)) * 100 : '-'
    //
    //   stat.analyticCr_fact = stat.clicksFact !== 0
    //       ? ((parseFloat(stat.analyticGoalsFact) / parseInt(stat.clicksFact)) * 100) : '-'
    //
    //   stat.analyticCost_fact = stat.analyticGoalsFact !== 0
    //       ? ((parseFloat(stat.consumptionPeriodOne) / parseFloat(stat.analyticGoalsFact))) : '-'
    //
    //   /* // calculate CRM values
    //   stat.roi = stat.consumption !== 0
    //     ? ((parseFloat(stat.profit) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'
    //
    //   stat.cr = stat.clicks !== 0
    //     ? ((parseFloat(stat.numberOrders) / parseInt(stat.clicks)) * 100) : '-'
    //
    //   stat.cost = stat.numberOrders !== 0
    //     ? ((parseFloat(stat.consumption) / parseFloat(stat.numberOrders))) : '-' */
    // },
    buildTable(filterTableData, isDefaultRange1, isDefaultRange2) {
      this.$store.dispatch('statistic/fetchSummaryTableCompare', filterTableData)
          .then(response => {
            // TO-DO: NEED refactoring
            const dataForParse = response.data
            if (dataForParse === 'NO_STATS') {
              this.emptyStats = dataForParse
            } else {
              if (isDefaultRange1 && isDefaultRange2) {
                this.$store.commit('statistic/LOAD_SUMMARY_TABLE_COMPARE_DATA', dataForParse)
              }
              this.calculateTableData(dataForParse)
            }
          })
          .catch(() => {

          })
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    // eslint-disable-next-line consistent-return
    transitionName() {
      if (this.useTransition) {
        return 'fade'
      }
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';
</style>
<style>
.flatpickr-input {
  width: 210px !important;
}

</style>
